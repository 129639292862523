import React from "react";
import arrow from "../../../../assets/company/arrow-down.svg";
import { Button } from "@react-md/button";
import { Link } from "gatsby";
import "./style.scss";
import { RichText } from "prismic-reactjs";

export default function JobTemplate({ data }) {
  return (
    <section className="job-template">
      <div className="container">
        <Link to="/company/careers">
          <button>
            <img src={arrow} alt="arrow" />
            Back to Careers Opportunities
          </button>
        </Link>
        <div className="header-wrapper">
          <RichText render={data.position} />
          <a href={data.apply_url.url}>
            <Button themeType="contained" className="btn btn-job">
              APPLY NOW
            </Button>
          </a>
        </div>
        <RichText render={data.type} />
        <RichText render={data.description} />
        {data.body.map((content, index) => {
          return (
            <div className="job-template-block" key={index}>
              <RichText render={content.primary.heading} />
              <RichText render={content.primary.points} />
            </div>
          );
        })}
        <RichText render={data.goal} />
        <a href={data.apply_url.url}>
          <Button themeType="contained" className="btn btn-job">
            APPLY NOW
          </Button>
        </a>
      </div>
    </section>
  );
}
