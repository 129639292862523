import React from "react";
import Layout from "../components/layout";
import JobTemplate from "../components/company/careers/job_template";
import SecondaryFooter from "../components/layout/secondaryFooter";
import { graphql } from "gatsby";

export default function Job(props) {
  if (!props.data) return null;
  const data = props.data.allPrismicCareers.edges[0].node.dataRaw;
  const type = props.data.allPrismicCareers.edges[0].node.type;
  return (
    <Layout type={type}>
      <JobTemplate data={data} />
      <SecondaryFooter />
    </Layout>
  );
}

export const job = graphql`
  query job($id: String!) {
    allPrismicCareers(filter: { id: { eq: $id } }) {
      edges {
        node {
          uid
          id
          type
          dataRaw
        }
      }
    }
  }
`;
